import React, { useEffect } from "react";
import { Link, navigate } from "@reach/router";
import "./styles.scss";
import {
  removeImpactClickIdCookie,
  getRawCookie,
  setImpactClickIdCookie,
  removeImpactMediaPartnerIdCookie,
  setImpactMediaPartnerIdCookie,
  setDocumentReferrer,
  removeDocumentReferrer
} from "../helpers";

const PageNotFound = () => {
  const browser =
    typeof window !== "undefined" &&
    window &&
    window.location &&
    window.location.pathname &&
    window.location.pathname.indexOf("login") === -1 &&
    window.location.pathname.indexOf("registration") === -1;

  useEffect(() => {
    //@ts-ignore
    if (
      global &&
      global.window &&
      global.window.location &&
      global.window.location.pathname
    ) {
      //@ts-ignore
      if (
        global.window.location.pathname.indexOf("login") > -1 ||
        global.window.location.pathname.indexOf("registration") > -1
      ) {
        // Setting document referrer
        const referrer =
          typeof document !== "undefined" &&
          document &&
          document.referrer &&
          document.referrer.indexOf("https://hexowatch.com") === -1 &&
          document.referrer;
        if (referrer && getRawCookie("document_referrer")) {
          removeDocumentReferrer();
        }
        referrer && setDocumentReferrer(referrer);
        // Setting Impact irclickid in cookies
        const url = window.location.href;
        if (url && url.indexOf("irclickid=") > -1) {
          if (getRawCookie("__irClickId")) {
            removeImpactClickIdCookie();
          }
          const id = url.slice(url.indexOf("irclickid=") + 10);
          const idFin = id.slice(0, id.indexOf("&"));
          setImpactClickIdCookie(idFin);
          removeImpactMediaPartnerIdCookie();
        } else if (
          url &&
          url.toLocaleLowerCase().indexOf("mediapartnerid=") > -1
        ) {
          if (getRawCookie("__mediaPartnerId")) {
            removeImpactMediaPartnerIdCookie();
          }
          const id = url.slice(
            url.toLocaleLowerCase().indexOf("mediapartnerid=") + 15
          );
          const idFin = id.slice(0, id.indexOf("&"));
          setImpactMediaPartnerIdCookie(idFin);
          removeImpactClickIdCookie();
        }
      }
      //@ts-ignore
      global.window.location.hash.indexOf("login") > -1
        ? navigate("/login")
        : //@ts-ignore
        global.window.location.hash.indexOf("registration") > -1
        ? navigate("/registration")
        : setTimeout(() => {
            navigate("/");
          }, 5000);
    }
  }, []);
  // setting 404 just in case we got one in SSR
  return (
    browser && (
      <section className="error_area">
        <div className="container flex">
          <div className="error_contain text-center">
            <div className="b_text">
              <h1 className="f_p w_color f_700">404</h1>
            </div>
            <h2 className="f_p f_400 w_color f_size_40">Page not found</h2>
            <p className="w_color">
              The page you're looking for might have been removed, had its name
              changed or is temporarily unavailable.
            </p>
            <Link to="/" className="about_btn btn_hover mt_40">
              Go to main page
            </Link>
          </div>
        </div>
      </section>
    )
  );
};

export default PageNotFound;
